<template>
  <div class="field has-addons">
    <p
      class="control control-input has-icons-left has-icons-right"
      :class="{ 'is-loading' : searchingProducts }"
    >
      <input
       v-on:keyup.enter="debounceSearch"
        v-model="searchInputQuery"
        class="input"
        :disabled="searchingProducts || loadingProducts"
        type="text"
        placeholder="Buscar productos...">
    </p>
    <div v-if="searchInputQuery && !searchingProducts" class="control">
      <div class="button button-clear" @click="clearSearch">
        x
      </div>
    </div>
    <div v-if="!searchingProducts && !loadingProducts" class="control">
      <div class="button button-search" @click="debounceSearch">
        <inline-svg :src="require('@/assets/icon-search.svg')" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex'
import { sync } from 'vuex-pathify'
import store from '@/store'

export default {
  data: function() {
    return {
      debounce: null
    }
  },
  computed: {
    ...mapState('products', ['searchingProducts', 'loadingProducts']),
    ...mapGetters('config', ['mode']),
    searchInputQuery: sync('products/searchInputQuery'),
    queryParams() {
      const query = {}
      if (this.mode) query.mode = this.mode
      return query
    }
  },
  methods: {
    ...mapActions('products', ['getProducts']),
    debounceSearch() {
      this.$router.push({ name: 'home', query: this.queryParams })
      this.search()
    },
    clearSearch() {
      store.set('products/searchInputQuery', '')
      store.set('products/productsCountToShow', 0)
      this.search()
    },
    search() {
      try {
        window.scrollTo({ top: 0, behavior: 'smooth' })
      } catch (e) {}
      const previousSearchQuery = store.get('products/searchQuery')
      const productsCountToShow = store.get('products/productsCountToShow')
      if (previousSearchQuery) {
        if (previousSearchQuery !== this.searchInputQuery) {
          store.set('products/productsCountToShow', 0)
        }

        if (productsCountToShow > 0) {
          store.set('products/productsCountToShow', 0)
        }
      }
      store.set('products/searchQuery', this.searchInputQuery)
      store.set('products/searchPage', 0)
      store.set('categories/categorieSelected', null)
      store.set('products/products', null)
      store.set('products/metadata', null)
      this.getProducts()
    }
  }
}
</script>

<style lang="scss" scoped>
.input {
  padding-left: 13px !important;
  border-color: var(--color-border);
  background-color: var(--color-primary);
  color: #c2c9d6;
}
input[disabled] {
  background-color: var(--color-primary);
  border-color: var(--color-border);
}
.input::placeholder {
  color: #bcc2ce;
}
.input:focus {
  border: 1px solid var(--color-tertiary);
  border-right: 0;
  box-shadow: 0 0 0 0 var(--color-tertiary);
}
.input:hover {
  border: 1px solid var(--color-tertiary);
  border-right: 0;
  box-shadow: 0 0 0 0 var(--color-tertiary);
}
.button-search {
  background-color: var(--color-tertiary);
  border-color: var(--color-tertiary);
  svg {
    max-width: fit-content;
    fill: var(--color-icon);
  }
}
.button-clear {
  background-color: var(--color-tertiary);
  color: var(--color-icon);
  border-color: var(--color-tertiary);
  margin-right: 2px;
}
.control-input {
  width: 100%;
}
</style>
