var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('section', [_c('div', {
    staticClass: "personal-info"
  }, [_vm._m(0), _c('form', [_c('div', {
    staticClass: "field"
  }, [_c('label', {
    staticClass: "label"
  }, [_vm._v("Tu nombre *")]), _c('div', {
    staticClass: "control"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model.trim",
      value: _vm.$v.client.name.$model,
      expression: "$v.client.name.$model",
      modifiers: {
        "trim": true
      }
    }],
    staticClass: "input",
    class: {
      error: _vm.$v.client.name.$invalid && _vm.$v.client.name.$dirty
    },
    attrs: {
      "type": "text",
      "placeholder": "Escribe tu nombre"
    },
    domProps: {
      "value": _vm.$v.client.name.$model
    },
    on: {
      "input": [function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.$v.client.name, "$model", $event.target.value.trim());
      }, function ($event) {
        return _vm.changeInput('client', 'name');
      }],
      "blur": function ($event) {
        return _vm.$forceUpdate();
      }
    }
  })]), _vm.$v.client.name.$dirty ? _c('div', [!_vm.$v.client.name.required ? _c('p', {
    staticClass: "help is-danger"
  }, [_vm._v("Ingresa tu nombre")]) : _vm._e()]) : _vm._e()]), _c('div', [_c('identification-client')], 1), _c('div', {
    staticClass: "columns"
  }, [_c('div', {
    staticClass: "column is-7"
  }, [_c('div', {
    staticClass: "field"
  }, [_c('label', {
    staticClass: "label"
  }, [_vm._v("Correo electrónico *")]), _c('div', {
    staticClass: "control"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model.trim",
      value: _vm.$v.client.email.$model,
      expression: "$v.client.email.$model",
      modifiers: {
        "trim": true
      }
    }],
    staticClass: "input",
    class: {
      error: _vm.$v.client.email.$invalid && _vm.$v.client.email.$dirty
    },
    attrs: {
      "type": "email",
      "placeholder": "Tu correo electrónico"
    },
    domProps: {
      "value": _vm.$v.client.email.$model
    },
    on: {
      "input": [function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.$v.client.email, "$model", $event.target.value.trim());
      }, function ($event) {
        return _vm.changeInput('client', 'email');
      }],
      "blur": function ($event) {
        return _vm.$forceUpdate();
      }
    }
  })]), _vm.$v.client.email.$dirty ? _c('div', [!_vm.$v.client.email.required ? _c('p', {
    staticClass: "help is-danger"
  }, [_vm._v("Ingresa tu email")]) : _vm._e(), !_vm.$v.client.email.email ? _c('p', {
    staticClass: "help is-danger"
  }, [_vm._v("Email invalido")]) : _vm._e()]) : _vm._e()])]), _c('div', {
    staticClass: "column is-5"
  }, [_c('div', {
    staticClass: "field"
  }, [_c('label', {
    staticClass: "label"
  }, [_vm._v("Teléfono *")]), _c('div', {
    staticClass: "control"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model.trim",
      value: _vm.$v.client.phone.$model,
      expression: "$v.client.phone.$model",
      modifiers: {
        "trim": true
      }
    }],
    staticClass: "input",
    class: {
      error: _vm.$v.client.phone.$invalid && _vm.$v.client.phone.$dirty
    },
    attrs: {
      "type": "number",
      "placeholder": "Tu teléfono de contacto"
    },
    domProps: {
      "value": _vm.$v.client.phone.$model
    },
    on: {
      "input": [function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.$v.client.phone, "$model", $event.target.value.trim());
      }, function ($event) {
        return _vm.changeInput('client', 'phone');
      }],
      "blur": function ($event) {
        return _vm.$forceUpdate();
      }
    }
  })]), _vm.$v.client.phone.$dirty ? _c('div', [!_vm.$v.client.phone.required ? _c('p', {
    staticClass: "help is-danger"
  }, [_vm._v(" Ingresa tu telefono de contacto ")]) : _vm.PHONE_NUMBER_LENGTH[_vm.applicationVersion] && (!_vm.$v.client.phone.minLength || !_vm.$v.client.phone.maxLength) ? _c('p', {
    staticClass: "help is-danger"
  }, [_vm._v(" Ingresa un número de teléfono válido (" + _vm._s(_vm.PHONE_NUMBER_LENGTH[_vm.applicationVersion]) + " dígitos) ")]) : _vm._e()]) : _vm._e()])])])])]), _c('div', {
    staticClass: "shipping-info"
  }, [_vm._m(1), _c('form', [_c('div', {
    staticClass: "columns"
  }, [_c('div', {
    staticClass: "column",
    class: _vm.activeCityShipping ? 'is-12' : 'is-6'
  }, [_c('div', {
    staticClass: "field"
  }, [_c('label', {
    staticClass: "label"
  }, [_vm._v("Dirección *")]), _c('div', {
    staticClass: "control"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model.trim",
      value: _vm.$v.address.address.$model,
      expression: "$v.address.address.$model",
      modifiers: {
        "trim": true
      }
    }],
    staticClass: "input",
    class: {
      error: _vm.$v.address.address.$invalid && _vm.$v.address.address.$dirty
    },
    attrs: {
      "type": "text",
      "placeholder": "Dirección de envío"
    },
    domProps: {
      "value": _vm.$v.address.address.$model
    },
    on: {
      "input": [function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.$v.address.address, "$model", $event.target.value.trim());
      }, function ($event) {
        return _vm.changeInput('address', 'address');
      }],
      "blur": function ($event) {
        return _vm.$forceUpdate();
      }
    }
  })]), _vm.$v.address.address.$dirty ? _c('div', [!_vm.$v.address.address.required ? _c('p', {
    staticClass: "help is-danger"
  }, [_vm._v(" Ingresa una dirección ")]) : _vm._e()]) : _vm._e()])]), !_vm.activeCityShipping ? _c('div', {
    staticClass: "column is-6"
  }, [_c('div', {
    staticClass: "field"
  }, [_c('label', {
    staticClass: "label"
  }, [_vm._v("Ciudad *")]), _c('div', {
    staticClass: "control"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model.trim",
      value: _vm.$v.address.city.$model,
      expression: "$v.address.city.$model",
      modifiers: {
        "trim": true
      }
    }],
    staticClass: "input",
    class: {
      error: _vm.$v.address.city.$invalid && _vm.$v.address.city.$dirty
    },
    attrs: {
      "type": "text",
      "placeholder": "Ciudad, País"
    },
    domProps: {
      "value": _vm.$v.address.city.$model
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.$v.address.city, "$model", $event.target.value.trim());
      },
      "blur": function ($event) {
        return _vm.$forceUpdate();
      }
    }
  })]), _vm.$v.address.city.$dirty ? _c('div', [!_vm.$v.address.city.required ? _c('p', {
    staticClass: "help is-danger"
  }, [_vm._v("Ingresa una ciudad")]) : _vm._e()]) : _vm._e()])]) : _vm._e()]), _c('div', {
    staticClass: "columns"
  }, [_vm.$v.address.zipcode ? _c('div', {
    staticClass: "column is-4"
  }, [_c('div', {
    staticClass: "field"
  }, [_c('label', {
    staticClass: "label"
  }, [_vm._v(" Código postal " + _vm._s(!_vm.$v.address.zipcode.required ? '*' : '') + " ")]), _c('div', {
    staticClass: "control"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model.trim",
      value: _vm.$v.address.zipcode.$model,
      expression: "$v.address.zipcode.$model",
      modifiers: {
        "trim": true
      }
    }],
    staticClass: "input",
    class: {
      error: _vm.$v.address.zipcode.$invalid && _vm.$v.address.zipcode.$dirty
    },
    attrs: {
      "type": "text",
      "placeholder": "Código postal (ZIP)"
    },
    domProps: {
      "value": _vm.$v.address.zipcode.$model
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.$v.address.zipcode, "$model", $event.target.value.trim());
      },
      "blur": function ($event) {
        return _vm.$forceUpdate();
      }
    }
  })]), _vm.$v.address.zipcode.$dirty ? _c('div', [!_vm.$v.address.zipcode.required ? _c('p', {
    staticClass: "help is-danger"
  }, [_vm._v(" Ingresa tu código postal ")]) : _vm._e(), !_vm.$v.address.zipcode.minLength || !_vm.$v.address.zipcode.maxLength ? _c('p', {
    staticClass: "help is-danger"
  }, [_vm._v(" Ingresa un código postal válido ")]) : _vm._e()]) : _vm._e()])]) : _vm._e()]), _vm.activeCityShipping ? _c('city-field') : _vm._e(), _c('div', {
    staticClass: "field"
  }, [_c('label', {
    staticClass: "label"
  }, [_vm._v("Información adicional (opcional)")]), _c('div', {
    staticClass: "control"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.address.additionalInfo,
      expression: "address.additionalInfo"
    }],
    staticClass: "input",
    attrs: {
      "type": "text",
      "placeholder": "Información adicional"
    },
    domProps: {
      "value": _vm.address.additionalInfo
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.address, "additionalInfo", $event.target.value);
      }
    }
  })])])], 1)])]);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "level header-shipping"
  }, [_c('div', {
    staticClass: "level-item"
  }, [_c('span', {
    staticClass: "icon"
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/icon-user.svg")
    }
  })]), _c('h2', {
    staticClass: "subtitle"
  }, [_vm._v("TUS DATOS")])])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "level header-shipping"
  }, [_c('div', {
    staticClass: "level-item"
  }, [_c('span', {
    staticClass: "icon"
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/icon-truck.svg")
    }
  })]), _c('h2', {
    staticClass: "subtitle"
  }, [_vm._v("DATOS DE ENVÍO")])])]);
}]

export { render, staticRenderFns }