import { make } from 'vuex-pathify'
import actions from './actions'

const inistialState = () => ({
  products: [],
  metadata: null,
  availableMoreProducts: true,
  loadingProducts: true,
  productDetail: null,
  searchQuery: '',
  searchPage: 0,
  productsCountToShow: 0,
  timesToSearch: 0,
  searchInputQuery: '',
  searchingProducts: false,
  lastAddedProduct: {
    product: null,
    quantity: null
  },
  featuredProducts: null,
  productsInCartInventory: null
})

const state = inistialState()

const mutations = make.mutations(state)

export default {
  namespaced: true,
  state,
  actions,
  mutations
}
