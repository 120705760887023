var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "field has-addons"
  }, [_c('p', {
    staticClass: "control control-input has-icons-left has-icons-right",
    class: {
      'is-loading': _vm.searchingProducts
    }
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.searchInputQuery,
      expression: "searchInputQuery"
    }],
    staticClass: "input",
    attrs: {
      "disabled": _vm.searchingProducts || _vm.loadingProducts,
      "type": "text",
      "placeholder": "Buscar productos..."
    },
    domProps: {
      "value": _vm.searchInputQuery
    },
    on: {
      "keyup": function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) { return null; }
        return _vm.debounceSearch.apply(null, arguments);
      },
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.searchInputQuery = $event.target.value;
      }
    }
  })]), _vm.searchInputQuery && !_vm.searchingProducts ? _c('div', {
    staticClass: "control"
  }, [_c('div', {
    staticClass: "button button-clear",
    on: {
      "click": _vm.clearSearch
    }
  }, [_vm._v(" x ")])]) : _vm._e(), !_vm.searchingProducts && !_vm.loadingProducts ? _c('div', {
    staticClass: "control"
  }, [_c('div', {
    staticClass: "button button-search",
    on: {
      "click": _vm.debounceSearch
    }
  }, [_c('inline-svg', {
    attrs: {
      "src": require('@/assets/icon-search.svg')
    }
  })], 1)]) : _vm._e()]);
}
var staticRenderFns = []

export { render, staticRenderFns }