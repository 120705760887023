var render = function () {
  var _vm$products, _vm$products2;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.loadingProducts ? _c('app-loading', {
    attrs: {
      "loading": _vm.loadingProducts
    }
  }) : [_vm.shouldShowSearchResultAmount ? _c('div', {
    staticClass: "results-search"
  }, [_vm._v(" Mostrando " + _vm._s(_vm.productsCountToShow) + " resultados para "), _c('strong', [_vm._v("\"" + _vm._s(_vm.searchQuery) + "\"")])]) : _vm._e(), _vm.shouldShowAllProductsTitle ? _c('div', {
    staticClass: "title-all-products"
  }, [_c('h2', [_vm._v("Nuestros productos")])]) : _vm._e(), (_vm$products = _vm.products) !== null && _vm$products !== void 0 && _vm$products.length ? _c('div', {
    staticClass: "all-products"
  }, _vm._l(_vm.products, function (product, index) {
    return _c('ProductCard', {
      key: index,
      attrs: {
        "product": product
      }
    });
  }), 1) : !((_vm$products2 = _vm.products) !== null && _vm$products2 !== void 0 && _vm$products2.length) && !_vm.loadingProducts ? _c('div', {
    staticClass: "empty-products"
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/icon-item-default.svg")
    }
  }), _c('h2', [_vm._v(_vm._s(_vm.emptyProductsText))])]) : _vm._e(), _vm.availableMoreProducts && !_vm.loadingProducts ? _c('div', {
    staticClass: "more-products"
  }, [_c('button', {
    ref: "loadMoreButton",
    staticClass: "button is-outlined",
    on: {
      "click": _vm.loadMoreProducts
    }
  }, [_vm._v(" Cargar más productos ")])]) : _vm._e()]], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }